import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/page-title"

const Services = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <PageTitle
        title="Get in touch"
        description="Let's talk about your project."
      />
      <section className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-sm-12">
              <div className="row">
                <div className="col-12">
                  <div
                    className="alert alert-success contact__msg"
                    role="alert"
                  >
                    Thank you, someone will be in touch shortly.
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-5 pl-4 mt-4 mt-lg-0">
              <h4>Location</h4>
              <p className="mb-3">Sheffield, UK</p>
              <h4>Contact Mail</h4>
              <p className="mb-3">hello@invently.co.uk</p>
              <h4>Website</h4>
              <p>www.invently.co.uk</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Services

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
